import './App.css';
import React, { useRef, useState } from 'react';
import Typical from 'react-typical'


function App() {
  const animDiv = useRef("");
  const [getOpen, setOpen] = useState(0);

  const changeSituation = () =>{
    if(getOpen === 1){
      animDiv.current.style.animation = "FromBottom 0.5s"
      setOpen(0);
    }
    else{
      animDiv.current.style.animation = "FromTop 0.5s"
      setOpen(1);
    }
  }

  return (
    <div className="App">
      <div className='centered'>
        <h1>New era of digital services</h1>
        <div style={{marginTop: "-35px"}}>
        <Typical steps={["with PuhuSoft",4000,"with developers",4000,"with you",4000]} loop={Infinity} wrapper="h1" />
        </div>
        <p>Puhu Games, Puhu Cloud and much more,<br />coming soon...</p>
        <div className='contact'>
          <a href='https://puhusoft.github.io'>Core</a>
          <a href='https://github.com/puhusoft'>Github</a>
          <a onClick={changeSituation}  className='last'>Devs</a>
          <div className='devs-rect'>
            <div className='animated-div' ref={animDiv}>
              <a className='last' href='https://emirhankursun.com'>Emirhan</a>
              <a className='last' href='http://huseyinbozkurt.com'>Hüseyin</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
